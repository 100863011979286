import { useEffect, useState } from "react";
import { SearchMenuSection } from "./model.Search";
import { useQuery } from '@apollo/client';
import { CONTENT_SEARCH_QUERY } from 'views/main/queries.Main';
import {
  ContentSearchQuery,
} from 'generated';
import { useLanguage } from "components/util/Language";

export function useSearchContentModel ({searchTerm, isSearchValid, noLimit}: {searchTerm: string; isSearchValid: boolean; noLimit?: boolean;}) {
  const lang = useLanguage();

  const [contentResultsState, setContentResultsState] = useState<SearchMenuSection | undefined>();

  const resetSearchContentResultsState = () => {
    setContentResultsState(undefined);
  }

  const updateContentResultsState = (searchResults: ContentSearchQuery) => {
    const searchResultOptions = searchResults.contentSearch.map((d) => ({
      label: d.title,
      value: d.path,
      ancestors: d.ancestors,
      content: d.content
    }));

    const newContentResultState = {
      optionType: { EN: 'Pages', FR: '[FR]Pages' },
      items: searchResultOptions,
    };

    if (searchResults?.contentSearch === undefined || searchResults?.contentSearch.length === 0) {
      return resetSearchContentResultsState();
    }

    setContentResultsState(newContentResultState);
  };

  const contentResultsQuery = useQuery(CONTENT_SEARCH_QUERY, {
    variables: { language: lang, text: searchTerm, noLimit },
    skip: !isSearchValid,
    returnPartialData: true,
    onCompleted: (data: ContentSearchQuery) => {
      updateContentResultsState(data);
    },
  });

  useEffect(() => {
    if (!isSearchValid) {
      resetSearchContentResultsState();
    }
  }, [isSearchValid]);


  return {
    contentResultsState,
    resetSearchContentResultsState,
    contentSearchPending: contentResultsQuery.loading
  }

}
